import React, { useState, useEffect, useRef, useContext } from "react";
import "../App.css";
import axios from "axios";
const FilterModal = ({
  isOpen,
  checkedTags,
  checkedDuration,
  checkedLevels,
  handleApplyFilter,
  handleResetFilter,
  handleClose,
  checkedInstructors,
  instructors
}) => {
  const tags = [
    { name: "Strength", _id: "6541eb0ff1f99641d644a37d" },
    { name: "Flexibility", _id: "6565b7a5e7fe9cfc044933fc" },
    { name: "Turns", _id: "6565f35a3e1f9b2470ce1e1d" },
    { name: "Jumps", _id: "6565f35e3e1f9b2470ce1e2d" },
    { name: "Warmup", _id: "65763ac421a48327d2f79386" },
    { name: "Breakdown", _id: "6565f37b3e1f9b2470ce1e86" },
  ];

  const duration = [
    { label: "< 5 Minutes", value: "5" },
    { label: "5-10 Minutes", value: "10" },
    { label: "10-15 Minutes", value: "15" },
    { label: "> 15 Minutes", value: "16" },
  ];

  const levels = [ 
    { label: "Beginner", value: "Beginner" },
    { label: "Intermediate", value: "Intermediate" },
    { label: "Advanced", value: "Advanced" },
  ];

  // instructors = [
  //   { name: "Jonathan", _id: "123" },
  //   { name: "Jane Smith", _id: "456" },
  // ];

  const [selectedTags, setSelectedTags] = useState(checkedTags || []);
  const [selectedDurations, setSelectedDurations] = useState(checkedDuration || []);
  const [selectedLevels, setSelectedLevels] = useState(checkedLevels || []);
  const [selectedInstructors, setSelectedInstructors] = useState(checkedInstructors || []);

  const handleTagChange = (tagId) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tagId)
        ? prevTags.filter((prevTag) => prevTag !== tagId)
        : [...prevTags, tagId]
    );

    setSelectedTags((updatedTags) => {
      console.log("selectedTags", updatedTags);
      return updatedTags; // Make sure to return the updated value
    });
  };

  const handleInstructorChange = (instructorName) => {
    console.log("instructorName", instructorName);
    setSelectedInstructors((prevInstructors) =>
      prevInstructors.includes(instructorName)
        ? prevInstructors.filter((prevInstructor) => prevInstructor !== instructorName)
        : [...prevInstructors, instructorName]
    );  
  }

  const handleDurationChange = (durationValue) => {
    setSelectedDurations((prevDurations) =>
      prevDurations.includes(durationValue)
        ? prevDurations.filter((prevDuration) => prevDuration !== durationValue)
        : [...prevDurations, durationValue]
    );
  };

  const handleLevelChange = (levelValue) => {
    setSelectedLevels((prevLevels) =>
      prevLevels.includes(levelValue)
        ? prevLevels.filter((prevLevel) => prevLevel !== levelValue)
        : [...prevLevels, levelValue]
    );
  };


  const handleResetFilters = () => {
    setSelectedTags([]);
    setSelectedDurations([]);
    setSelectedLevels([]);
    setSelectedInstructors([]);
    handleApplyFilter();
    // Additional logic if needed
  };

  const handleApplyFilters = () => {
    // You can perform actions here based on the selected filters
    // For example, make an API call using handleApplyFilter(selectedTags, selectedDurations)
    console.log(selectedTags, selectedDurations, selectedLevels, selectedInstructors);
    handleApplyFilter(selectedTags, selectedDurations, selectedLevels, selectedInstructors);
  };

  return (
    <div className="c-modal-overlay">
      <div className="c-tag">
        <div className="c-tag_pop">
          <div>
            <div className="c-tag_pop-header">
              <h2>Filters</h2>
              <a onClick={handleClose}>
                <i className="fa-solid fa-xmark"></i>
              </a>
            </div>
            <div className="c-tag_body-m">
              <div className="c-tag_body">
                <div className="c-tag_body_one">
                  <h2>Duration</h2>
                  <div className="c-tag_body_from">
                    {duration?.map((dur) => (
                      <div className="c-tag_body_from-input" key={dur.value}>
                        <label htmlFor={dur.value}>
                          {dur.label}
                        </label>
                        <input
                          type="checkbox"
                          id={dur.value}
                          checked={selectedDurations?.includes(dur.value)}
                          onChange={() => handleDurationChange(dur.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="c-tag_body_one">
                  <h2>Focus</h2>
                  <div className="c-tag_body_from">
                    {tags?.map((tag) => (
                      <div className="c-tag_body_from-input" key={tag._id}>
                        <label htmlFor={tag._id}>{tag.name}</label>
                        <input
                          type="checkbox"
                          id={tag._id}
                          checked={selectedTags?.includes(tag._id)}
                          onChange={() => handleTagChange(tag._id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="c-tag_body_one">
                  <h2>Level</h2>
                  <div className="c-tag_body_from">
                    {levels?.map((level) => (
                      <div className="c-tag_body_from-input" key={level.value}>
                        <label htmlFor={level.value}>
                          {level.label}
                        </label>
                        <input
                          type="checkbox"
                          id={level.value}
                          checked={selectedLevels?.includes(level.value)}
                          onChange={() => handleLevelChange(level.value)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {/* <div className="c-tag_body_one">
                  <h2>Instructors</h2>
                  <div className="c-tag_body_from">
                    {instructors?.length > 0 ?
                      instructors?.map((instructor) => (
                      <div className="c-tag_body_from-input" key={instructor}>
                        <label htmlFor={instructor}>
                          {instructor}
                        </label>
                        <input
                          type="checkbox"
                          id={instructor.name}
                          checked={selectedInstructors?.includes(instructor)}
                          onChange={() => handleInstructorChange(instructor)}
                        />
                      </div>
                    ))
                    : <p>No Instructor Found</p>}
                  </div>
                </div> */}
                
              </div>
              <div className="c-tag_btn">
                <div className="c-tag_btn-box">
                  <button
                    onClick={handleResetFilters}
                    className="c-tag_btn-reset"
                  >
                    reset
                  </button>
                  <button
                    onClick={handleApplyFilters}
                    className="c-tag_btn-apply"
                  >
                    apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;