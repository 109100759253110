import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import axios from "axios";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import "../Adminassets/TagInput.css";
import "../Adminassets/Style.css";
import { useNavigate } from "react-router-dom";
import Spinner from "../component/Spinner";
import { FormCheck } from "react-bootstrap";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import toast from "react-hot-toast";
import { FormControl, InputLabel, Checkbox, ListItemText } from "@mui/material";
import api from "../data/api";
import { handleDateChange } from "./utils/handleDateChange";
import { userInfoAtom } from "../data/atoms/utilsAtoms";
import { useAtomValue } from "jotai";
import CalendarInput from "../component/CalendarInput";

const AddVideos = () => {
  axios.defaults.withCredentials = true;
  const userData = useAtomValue(userInfoAtom);
  const redirect = useNavigate();
  const [query, setQuery] = useState("");
  const [productQuery, setProductQuery] = useState("");
  const [videoTypes, setVideoTypes] = useState([]);
  const [backendTags, setBackendTags] = useState([]);
  const [backendProducts, setBackendProducts] = useState([]);
  const [videoId, setVideoId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTagsUI, setShowTagsUI] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchIsLoading, setFetchIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [presentInstrutors, setPresentInstructors] = useState([]);
  const [isAddingNewInstructor, setIsAddingNewInstructor] = useState(false);
  const [newInstructor, setNewInstructor] = useState(""); // for new instructor input

  useEffect(() => {
    if (userData?.token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/get-instructors`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        })
        .then((response) => {
          setPresentInstructors(response.data.instructors);
        })
        .catch((error) => {
          toast.error("Something went wrong", {
            position: "top-center",
            duration: 3000,
          });
        });
    }
  }, []);

  const startPickingFrom = new Date();
  startPickingFrom.setDate(startPickingFrom.getDate() + 1);
  startPickingFrom.setHours(0);
  startPickingFrom.setMinutes(1);

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    videoId: "",
    tags: [],
    products: [],
    CategoryId: "",
    videoLevel: [],
    videolink: "",
    thumnailLink: "",
    duration: 0,
    releaseDateTime: new Date(),
    isFeatured: false,
    instructor: "",
  });

  //check routing
  useEffect(() => {
    api.getCategory().then((response) => {
      setVideoTypes(response.data);
    });
  }, []);

  useEffect(() => {}, [formData.products]);

  useEffect(() => {
    const performSearch = async (querySpecial) => {
      try {
        // const response = await axios.get(`${process.env.REACT_APP_API_URL}/search/tags?query=${query}`);
        const response = await api.searchTags(querySpecial);
        const newBackendTags = [];
        // eslint-disable-next-line array-callback-return
        response.data?.map((tag) => {
          let flag = true;
          // eslint-disable-next-line array-callback-return
          formData?.tags.map((tag1) => {
            if (tag1 === tag.name) {
              flag = false;
            }
          });
          if (flag) {
            newBackendTags.push(tag);
          }
        });
        setBackendTags(newBackendTags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    if (query.trim()) {
      const querySpecial = query.trim();
      performSearch(querySpecial);
    } else {
      setBackendTags([]);
    }
  }, [query]);

  useEffect(() => {
    const performSearch = async (querySpecial) => {
      try {
        const response = await api.searchProducts(querySpecial);
        setBackendProducts(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (productQuery.trim()) {
      const querySpecial = productQuery.trim();
      performSearch(querySpecial);
    } else {
      setBackendProducts([]);
    }
  }, [productQuery]);

  const handleChange = ({ target: { name, value } }) => {
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const handleDescriptionChange = (value) => {
    setFormData((formData) => ({ ...formData, description: value }));
  };

  const showLoader = () => {
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
  };

  const handleTagRemove = (tag) => {
    const updatedTags = formData.tags.filter((t) => t !== tag);
    setFormData({ ...formData, tags: updatedTags });
  };
  const handleProductRemove = (product) => {
    const updatedProducts = formData.products.filter((t) => t !== product);
    setFormData({ ...formData, products: updatedProducts });
  };

  const handleTagAdd = (newTag) => {
    if (
      newTag?.trim().length > 0 &&
      !formData?.tags.includes((t) => t.name !== newTag)
    ) {
      setFormData((prevData) => ({
        ...prevData,
        tags: [newTag, ...prevData.tags],
      }));
    }
  };

  const handleProductAdd = (newProduct) => {
    if (
      newProduct.trim().length > 0 &&
      !formData.products.includes(newProduct)
    ) {
      setFormData((prevData) => ({
        ...prevData,
        products: [newProduct, ...prevData.products],
      }));

      setProductQuery("");
    }
  };

  const fetchVideoData = async (e) => {
    e.preventDefault();
    try {
      setFetchIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/vimeo/video/details`,
        {
          videoId,
        }
      );

      if (response.status === 200) {
        const videoData = response.data.videoData;
        setFormData((formData) => ({
          ...formData,
          title: videoData?.name,
          description: videoData?.description,
          videolink: videoData?.player_embed_url,
          thumnailLink: videoData?.pictures?.base_link,
          duration: videoData?.duration,
          instructor: videoData?.instructor,
        }));
        setFetchIsLoading(false);
      } else if (response.status === 201 || response.status === 401) {
        toast.error("Please Login Again", {
          position: "top-center",
          duration: 3000,
        });
        redirect("/login");
      }
    } catch (error) {
      setFetchIsLoading(false);
      toast.error("Somethng went wrong", {
        position: "top-center",
        duration: 3000,
      });
      console.error("Error fetching video data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    showLoader();

    try {
      //setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/videos`,
        {
          formData,
        }
      );

      if (response.status === 200) {
        setFormData({
          title: "",
          description: "",
          CategoryId: "",
          videoId: "",
          videoLevel: [],
          tags: [],
          products: [],
          videolink: "",
          thumnailLink: "",
          duration: 0,
          releaseDateTime: "",
          isFeatured: false,
          instructor: "",
        });
        setQuery("");
        setShowTagsUI(false); // Reset the visibility of tags UI
        hideLoader();
        setSuccessMessage("Success! Data submitted successfully!");
        //console.log("Data saved successfully.");
      } else if (response.status === 201 || response.status === 401) {
        toast.error("Please Login Again", {
          position: "top-center",
          duration: 3000,
        });
        redirect("/login");
      } else {
        // console.error("Unexpected response status:", response.status);
        setErrorMessage("Danger! Error submitting data. Please try again.");
        // Handle other response statuses here
      }
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 20000);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSuccessMessage("");
      setErrorMessage("");
    }, 20000);

    return () => clearTimeout(timer); // Clear the timer when the component unmounts or when successMessage/ errorMessage changes
  }, [successMessage, errorMessage]);

  const handleVideoLevelChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      videoLevel: event.target.value,
    }));
  };

  return (
    <div>
      {isLoading && <Spinner />}

      {successMessage && <div className="alert-success">{successMessage}</div>}

      {errorMessage && <div className="alert-danger">{errorMessage}</div>}

      {!isLoading && (
        <Card className="p-3">
          <CardContent>
            <Form>
              <Form.Group controlId="videoId">
                <Form.Label>Video ID</Form.Label>
                <Form.Control
                  type="text"
                  name="videoId"
                  placeholder="Enter video ID"
                  value={videoId}
                  onChange={(e) => {
                    setVideoId(e.target.value);
                    handleChange(e);
                  }}
                />
              </Form.Group>

              <div
                style={{ display: "flex", alignItems: "center", gap: "24px" }}
              >
                <Button
                  className="mt-2 mb-2"
                  variant="primary"
                  type="button"
                  onClick={fetchVideoData}
                >
                  Fetch
                </Button>

                {fetchIsLoading && (
                  <div
                    style={{
                      width: "max-content",
                      height: "max-content",
                      marginTop: "16px",
                    }}
                  >
                    <Spinner style={{ position: "relative" }} />
                  </div>
                )}
              </div>

              <Form.Group controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Enter title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="videolink">
                <Form.Label>Video Link</Form.Label>
                <Form.Control
                  type="text"
                  name="videolink"
                  placeholder="Enter Video Link"
                  value={formData.videolink}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group controlId="thumnailLink">
                <Form.Label>Thumnail Link</Form.Label>
                <Form.Control
                  type="text"
                  name="thumnailLink"
                  placeholder="Enter Thumnail Link"
                  value={formData.thumnailLink}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group controlId="instructor">
                <Form.Label>Instructor</Form.Label>
                <Form.Control
                  as="select"
                  name="instructor"
                  value={formData.instructor || ""}
                  onChange={(e) => {
                    const selectedInstructor = e.target.value;
                    if (selectedInstructor === "add_new") {
                      setIsAddingNewInstructor(true); // Show new instructor input
                      setFormData((prevData) => ({
                        ...prevData,
                        instructor: "",
                      }));
                    } else {
                      setIsAddingNewInstructor(false); // Hide new instructor input if an existing one is selected
                      setFormData((prevData) => ({
                        ...prevData,
                        instructor: selectedInstructor,
                      }));
                    }
                  }}
                >
                  <option value="">Select Instructor</option>
                  {presentInstrutors.map((instructor) => (
                    <option key={instructor._id} value={instructor}>
                      {instructor}
                    </option>
                  ))}
                  <option value="add_new">Add New Instructor</option>
                </Form.Control>
              </Form.Group>

              {isAddingNewInstructor && (
                <Form.Group controlId="newInstructor">
                  <Form.Label>New Instructor</Form.Label>
                  <Form.Control
                    type="text"
                    name="newInstructor"
                    placeholder="Enter new instructor name"
                    value={newInstructor}
                    onChange={(e) => setNewInstructor(e.target.value)}
                  />
                </Form.Group>
              )}

              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <ReactQuill
                  value={formData.description}
                  onChange={handleDescriptionChange}
                />
              </Form.Group>

              <FormControl className="mt-1" fullWidth>
                <InputLabel id="category-label">Video Category</InputLabel>
                <Select
                  labelId="category-label"
                  id="CategoryId"
                  name="CategoryId"
                  value={formData.CategoryId}
                  label="Video Category"
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      CategoryId: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">
                    <em>Select a category</em>
                  </MenuItem>
                  {videoTypes.map((type, index) => (
                    <MenuItem key={index} value={type._id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className="mt-1" fullWidth>
                <InputLabel id="video-level">Choose the Level</InputLabel>
                <Select
                  labelId="video-level"
                  id="videoLevel"
                  name="videoLevel"
                  multiple
                  value={formData.videoLevel}
                  onChange={handleVideoLevelChange}
                  label="Choose the Level"
                  renderValue={(selected) => selected.join(", ")} // Display the selected values
                >
                  <MenuItem value="" disabled>
                    <em>Select The Level of the Video</em>
                  </MenuItem>
                  <MenuItem value="Beginner">
                    <Checkbox
                      checked={formData.videoLevel.includes("Beginner")}
                    />
                    <ListItemText primary="Beginner" />
                  </MenuItem>
                  <MenuItem value="Intermediate">
                    <Checkbox
                      checked={formData.videoLevel.includes("Intermediate")}
                    />
                    <ListItemText primary="Intermediate" />
                  </MenuItem>
                  <MenuItem value="Advanced">
                    <Checkbox
                      checked={formData.videoLevel.includes("Advanced")}
                    />
                    <ListItemText primary="Advanced" />
                  </MenuItem>
                </Select>
              </FormControl>

              <Form.Group controlId="duration">
                <Form.Label>Duration</Form.Label>
                <Form.Control
                  type="text"
                  name="duration"
                  placeholder="Enter Duration"
                  value={formData.duration}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Label>Tags</Form.Label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Autocomplete
                  freeSolo
                  options={backendTags.map((tag) => tag.name)}
                  inputValue={query}
                  onInputChange={(event, newInputValue) =>
                    setQuery(newInputValue)
                  }
                  onChange={(e, option) => {
                    console.log(formData, option);
                    handleTagAdd(option);
                    setQuery("");
                    console.log("query blanked");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Add Tag" fullWidth />
                  )}
                />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginRight: "5px",
                  }}
                >
                  {formData?.tags.map((tag, index) => (
                    <div key={index} className="tag">
                      {tag} <span onClick={() => handleTagRemove(tag)}>x</span>
                    </div>
                  ))}
                </div>
              </div>

              <Form.Label>Products</Form.Label>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Autocomplete
                  freeSolo
                  options={backendProducts.map((product) => product.title)}
                  inputValue={productQuery}
                  onInputChange={(event, newInputValue) =>
                    setProductQuery(newInputValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Add Product"
                      fullWidth
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "tab") {
                          handleProductAdd(e.target.value);
                        }
                      }}
                    />
                  )}
                />
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginRight: "5px",
                  }}
                >
                  {formData.products.map((product, index) => (
                    <div key={index} className="tag">
                      {product}
                      <span onClick={() => handleProductRemove(product)}>
                        x
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <FormControl
                controlId="description"
                style={{
                  width: "100%",
                  marginTop: "16px",
                }}
              >
                <Form.Label>Release Date</Form.Label>
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    zIndex: 1000,
                  }}
                >
                  <DatePicker
                    selected={formData?.releaseDateTime || new Date()}
                    onChange={(date) => {
                      handleDateChange(date, setFormData);
                    }}
                    dateFormat="MMMM d, yyyy"
                    customInput={<CalendarInput />}
                  />
                </div>
              </FormControl>

              <FormCheck
                className="mt-1"
                type="checkbox"
                id="isFeatured"
                label="Add to Featured Playlist"
                checked={formData.isFeatured}
                onChange={() =>
                  setFormData((prevData) => ({
                    ...prevData,
                    isFeatured: !prevData.isFeatured,
                  }))
                }
              />

              <Button
                className="mt-4"
                variant="primary"
                type="button"
                onClick={handleSubmit}
              >
                Save
              </Button>
              {loading ? <CircularProgress size={24} /> : null}
            </Form>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default AddVideos;
