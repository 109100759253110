import React, { useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import "../Adminassets/Style.css";
import {
  Button,
  Modal,
  Fade,
  TextField,
  Card,
  List,
  ListItem,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  MenuItem,
  Pagination,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../Adminassets/listVideo.css";
import Form from "react-bootstrap/Form";
import Autocomplete from "@mui/material/Autocomplete";
import Spinner from "../component/Spinner";
import { FormCheck } from "react-bootstrap";
import Button1 from "react-bootstrap/Button";
import toast from "react-hot-toast";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../data/atoms/utilsAtoms";
import CalendarInput from "../component/CalendarInput";
import { handleDateChange } from "./utils/handleDateChange";

const AllVideos = () => {
  axios.defaults.withCredentials = true;
  const userData = useAtomValue(userInfoAtom);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [totvideos, setTotVideos] = useState("");

  const redirect = useNavigate();
  const [query, setQuery] = useState("");
  const [videoTypes, setVideoTypes] = useState([]);
  const [backendTags, setBackendTags] = useState([]);
  const [videos, setVideos] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [productQuery, setProductQuery] = useState("");
  const [backendProducts, setBackendProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortby, setSortBy] = useState("latest");
  const [presentInstrutors, setPresentInstructors] = useState([]);
  const [isAddingNewInstructor, setIsAddingNewInstructor] = useState(false);
  const [newInstructor, setNewInstructor] = useState(""); // for new instructor input

  useEffect(() => {
    if (userData?.token) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/get-instructors`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        })
        .then((response) => {
          setPresentInstructors(response.data.instructors);
        }).catch((error) => {
          toast.error("Something went wrong", {
            position: "top-center",
            duration: 3000,
          });
        });
    }
  },[open]);
  const startPickingFrom = new Date();
  startPickingFrom.setDate(startPickingFrom.getDate() + 1);
  startPickingFrom.setHours(0);
  startPickingFrom.setMinutes(1);
  const excludedDates = new Date();
  excludedDates.setFullYear(excludedDates.getFullYear() - 30);

  const [editedVideoData, setEditedVideoData] = useState({
    _id: "",
    title: "",
    description: "",
    tags: [],
    products: [],
    CategoryId: "",
    videoId: "",
    videoLevel: [],
    videolink: "",
    thumnailLink: "",
    duration: 0,
    releaseDateTime: "",
    isFeatured: false,
    instructor: "",
  });

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingUser(null);
    setIsAddingNewInstructor(false);
    setEditedVideoData({
      _id: "",
      title: "",
      description: "",
      tags: [],
      products: [],
      CategoryId: "",
      videoLevel: [],
      videoId: "",
      videolink: "",
      thumnailLink: "",
      duration: 0,
      releaseDateTime: "",
      instructor: "",
      // isFeatured: false,
    });
  };

  const handleEditClick = (video) => {
    console.log("video", video);
    setEditingUser(video);

    const formattedCreate = new Date(video.releaseDateTime)
      .toISOString()
      .slice(0, 16);

    console.log("SetEditedVideo: ", {
      _id: video._id,
      title: video.title,
      description: video.description,
      CategoryId: video.CategoryId,
      videoId: video.videoId,
      videolink: video.videolink,
      videoLevel: video.videoLevel,
      thumnailLink: video.thumnailLink,
      duration: video.duration,
      releaseDateTime: formattedCreate,
      tags: video.tagsData,
      products: video.productsData,
      isFeatured: video.isFeatured,
      instructor: video.instructor,
    });

    setEditedVideoData({
      _id: video._id,
      title: video.title,
      description: video.description,
      CategoryId: video.CategoryId,
      videoId: video.videoId,
      videolink: video.videolink,
      videoLevel: video.videoLevel,
      thumnailLink: video.thumnailLink,
      duration: video.duration,
      releaseDateTime: formattedCreate,
      tags: video.tagsData,
      products: video.productsData,
      isFeatured: video.isFeatured,
      instructor: video.instructor,
    });

    handleOpen();
  };

  const getCurrentDate = () => {
    var timestamp = Date.parse(editedVideoData?.releaseDateTime);

    if (isNaN(timestamp) === false) {
      return new Date(timestamp);
    } else {
      return new Date();
    }
  };

  const updateVideo = useCallback(
    (videoId, editedVideoDataParam) => {
      const data = editedVideoDataParam
        ? editedVideoDataParam
        : editedVideoData;
      // console.log({ data });
      axios
        .put(`${process.env.REACT_APP_API_URL}/video/update/${videoId}`, data)
        .then((response) => {
          if (response.status === 200) {
            toast.success("Video updated", {
              position: "top-center",
              duration: 3000,
            });
            // Video updated successfully, update the state or perform any necessary actions
            // console.log("Video updated successfully.", response.data);
            // You can also refresh the video list by making a new GET request here if needed
            // fetchVideos();
          } else if (response.status === 201) {
            toast.error("Please Login Again", {
              position: "top-center",
              duration: 3000,
            });
          }
        })
        .catch((error) => {
          toast.error("Somethng went wrong", {
            position: "top-center",
            duration: 3000,
          });
          console.error("Error updating video:", error);
        })
    },
    [videos, editedVideoData]
  );

  // Function to handle page change in pagination
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleCancelEdit = () => {
    // Reset the editing state
    setEditingUser(null);
    setIsAddingNewInstructor(false);
    setEditedVideoData({
      title: "",
      description: "",
      tags: [],
      products: [],
      videoLevel: [],
      CategoryId: "",
      videoId: "",
      videolink: "",
      thumnailLink: "",
      duration: 0,
      releaseDateTime: "",
      isFeatured: false,
    });
    handleClose();
  };

  const handleDeleteVideo = (videoId) => {
    // Make a DELETE request to the API to delete the video
    setDeleteConfirmationOpen(false); // Close the confirmation dialog
    axios
      .delete(`${process.env.REACT_APP_API_URL}/video/delete/${videoId}`)
      .then((response) => {
        if (response.status === 200) {
          // Video deleted successfully, update the state or perform any necessary actions
          // console.log("Video deleted successfully.");
          toast.success("Video deleted", {
            position: "top-center",
            duration: 3000,
          });
          // You can also refresh the video list by making a new GET request here if needed
          const refreshedVideos = axios
            .get(`${process.env.REACT_APP_API_URL}/api/videos`)
            .then((response) => {
              setVideos(response.data);
            });
          //fetchVideos();
        } else if (response.status === 201) {
          toast.error("Please Login Again", {
            position: "top-center",
            duration: 3000,
          });
          redirect("/login");
        }
      })
      .catch((error) => {
        toast.error("Somethng went wrong", {
          position: "top-center",
          duration: 3000,
        });
        console.error("Error deleting video:", error);
      });
  };

  useEffect(() => {
    // Fetch video categories on component mount
    const fetchVideoCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/category`
        );
        setVideoTypes(response.data);
      } catch (error) {
        console.error("Error fetching video categories:", error);
      }
    };

    fetchVideoCategories();
  }, []);

  useEffect(() => {
    // Fetch tags based on user input
    const performSearch = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/search/tags?query=${query}`
        );
        const newBackendTags = [];
        response.data?.map((tag) => {
          let flag = true;
          editedVideoData?.tags.map((tag1) => {
            if (tag1.name === tag.name) {
              flag = false;
            }
          });
          if (flag) {
            newBackendTags.push(tag);
          }
        });
        setBackendTags(newBackendTags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    if (query.trim()) {
      performSearch();
    } else {
      setBackendTags([]);
    }
  }, [query]);

  const tagsRef = useRef(null);

  const handleTagAdd = (newTag) => {
    if (
      newTag?.trim().length > 0 &&
      !editedVideoData.tags.includes((t) => t.name !== newTag)
    ) {
      setEditedVideoData((prevData) => ({
        ...prevData,
        tags: [{ name: newTag, color: "gray" }, ...prevData.tags],
      }));
    }
  };

  // Modify the handleTagRemove function to remove the tag from the state and database
  const handleTagRemove = (tag) => {
    if (tag && tag.name) {
      const updatedTags = editedVideoData.tags.filter(
        (t) => t.name !== tag.name
      );
      setEditedVideoData((prevData) => ({
        ...prevData,
        tags: updatedTags,
      }));

      // Check if the tag has an _id property
      if (tag._id) {
        // Remove the tag from the database using the correct tag _id
      } else {
        console.error("Tag does not have an _id property:", tag);
      }
    } else {
      console.error("Invalid tag object:", tag);
    }
  };

  useEffect(() => {
    const performSearch = async (querySpecial) => {
      // console.log(querySpecial);
      try {
        const updatedProducts = editedVideoData.products;
        // console.log(updatedProducts);
        let prodId = [];
        if (updatedProducts && updatedProducts.length > 0) {
          updatedProducts.map((type, index) => prodId.push(type._id));
        }
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/search/products?query=${querySpecial}&prodId=${prodId}`
        );
        setBackendProducts(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    if (productQuery.trim()) {
      const querySpecial = productQuery.trim();
      // console.log(`queryData${querySpecial}`);
      performSearch(querySpecial);
    } else {
      setBackendProducts([]);
    }
  }, [productQuery]);

  const handleProductAdd = useCallback(
    async (newProduct) => {
      if (
        newProduct.trim().length > 0 &&
        (!editedVideoData.products ||
          !editedVideoData.products.includes(newProduct))
      ) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/shopify/product?query=${newProduct}`
          );

          if (response.data) {
            const dataOfInterest = {
              _id: response.data._id,
              title: response.data.title,
            };
            // Ensure prevData.products is an array or initialize it as an array
            const updatedProducts = Array.isArray(editedVideoData.products)
              ? [dataOfInterest, ...editedVideoData.products]
              : [dataOfInterest];

            const newData = {
              ...editedVideoData,
              products: [...updatedProducts],
            };
            setEditedVideoData({
              ...newData,
            });
            setProductQuery("");
            return { ...newData };
          }

          setProductQuery("");
        } catch (error) {
          console.error(error);
        }
      }
    },
    [editedVideoData]
  );

  const productRemoved = useRef(false);

  const handleSaveUser = useCallback(async () => {
    if (editingUser) {
      if (!productRemoved.current) {
        const newData = await handleProductAdd(productQuery);
        updateVideo(editingUser.v_id, newData);
      } else {
        updateVideo(editingUser.v_id, editedVideoData);
      }

      setTimeout(() => {
        fetchData();
      }, 500);
    }

    setEditingUser(null);
    setEditedVideoData((prevData) => ({
      ...prevData,
      tags: [], // Clear the tags array
    }));
    productRemoved.current = false;
    handleClose();
  }, [editingUser, editedVideoData, productQuery, productRemoved]);

  const showLoader = () => {
    setIsLoading(true);
  };

  const hideLoader = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, sortby]);

  const handleSearchVideo = async (event) => {
    event.preventDefault();
    if (searchTerm === "" || searchTerm.length > 2) {
      setCurrentPage(1);
      fetchData();
    }
  };

  const fetchData = async () => {
    try {
      showLoader();
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      // console.log(sortby);
      axios
        .post(`${process.env.REACT_APP_API_URL}/video/fetch`, {
          startIndex,
          endIndex,
          searchTerm,
          sortby,
        })
        .then((response) => {
          setTimeout(() => {
            hideLoader();
            console.log(response.data.result)
            setVideos(response.data.result);
            // console.log(response.data);
            // console.log(Math.ceil(response.data.totalRecords / itemsPerPage));
            setTotVideos(Math.ceil(response.data.totalRecords / itemsPerPage));
          }, 500);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  const handleFilterChange = (event) => {
    setSortBy(event.target.value);
    // Remove the fetchData call from here
  };

  const handleProductRemove = useCallback(
    (product) => {
      const updatedProducts = editedVideoData.products.filter(
        (t) => t._id !== product
      );
      setEditedVideoData({ ...editedVideoData, products: updatedProducts });
      productRemoved.current = true;
    },
    [editedVideoData, productRemoved]
  );

  const handleVideoLevelChange = (event) => {
    setEditedVideoData((prevData) => ({
      ...prevData,
      videoLevel: event.target.value,
    }));
  };

  return (
    <div>
      <h2>Video List</h2>
      <div>{isLoading ? <Spinner /> : ""}</div>
      <div className="col-md-12 search-top-container">
        <div className="col-md-8">
          <div className="search-box-container">
            <form onSubmit={handleSearchVideo}>
              <input
                type="text"
                id="search"
                placeholder="Search"
                value={searchTerm}
                className="search-input"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Button1 variant="primary">Search</Button1>
            </form>
          </div>
        </div>
        <div className="col-md-4">
          <div class="sort-container">
            <label for="sort">Sort by:</label>
            <select
              id="sort"
              class="sort-dropdown"
              onChange={handleFilterChange}
            >
              <option value={"latest"}>Latest First</option>
              <option value={"oldest"}>Oldest First</option>
              <option value={"title_asc"}>Title (A - Z)</option>
              <option value={"title_desc"}>Title (Z - A)</option>
              <option value={"long_duration"}>Long duration</option>
              <option value={"short_duration"}>Short duration</option>
            </select>
          </div>
        </div>
      </div>
      {!isLoading && (
        <List>
          {videos?.map((video) => (
            <ListItem key={video._id}>
              <Card style={{ width: "100%" }}>
                <div className="video-item list-Video">
                  <div className="video-thumbnail">
                    <img
                      className="w-10 h-10"
                      style={{ width: "100%", height: "100%" }}
                      src={video.thumnailLink}
                      alt=""
                    />
                  </div>
                  <div className="video-details">
                    <h2>
                      <strong>{video?.title}</strong>
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{ __html: video.description }}
                    />
                    <span className="tag-video">
                      <strong>Tags:</strong>
                      <ul
                        style={{
                          display: "flex",
                          listStyleType: "none",
                          padding: "0px",
                        }}
                      >
                        {video.tagsData?.map((tag, index) => (
                          <li
                            key={index}
                            style={{
                              backgroundColor: tag.color,
                              margin: "5px 9px 7px 6px",
                              border: "0px solid black",
                              borderRadius: "3px",
                              color: "#fff",
                              padding: "2px 10px",
                              textTransform: "capitalize",
                            }}
                          >
                            {tag.name}
                          </li>
                        ))}
                      </ul>
                    </span>
                    <div className="catName">
                      <strong>Category:</strong>
                      <span>{video.CategoryDetails[0]?.name}</span>
                    </div>
                    <div className="video-actions">
                      <button
                        style={{
                          margin: "3px",

                          padding: "10px 20px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                        className="btn btn-secondary"
                        onClick={() => handleEditClick(video)}
                      >
                        Edit
                      </button>
                      <button
                        style={{
                          margin: "1vh",

                          padding: "10px 20px",
                          fontSize: "15px",
                          textTransform: "uppercase",
                        }}
                        className="btn btn-dark"
                        onClick={() => {
                          setEditingUser(video);
                          setDeleteConfirmationOpen(true);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </Card>
            </ListItem>
          ))}
        </List>
      )}
      {/* Material-UI Pagination */}
      {!isLoading && (
        <div
          style={{
            margin: "20px 0",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={totvideos}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </div>
      )}

      {/* Material-UI Modal for Edit */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Fade in={open}>
          <div>
            <Card
              style={{
                background: "#fff",
                maxWidth: 900,
                width: "100%",
                padding: 16,
                maxHeight: "90vh",
                overflow: "auto",
              }}
            >
              <CardContent>
                <h2>Edit Video</h2>
                <TextField
                  label="Title"
                  fullWidth
                  margin="normal"
                  value={editedVideoData.title}
                  onChange={(e) =>
                    setEditedVideoData({
                      ...editedVideoData,
                      title: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Video Link"
                  fullWidth
                  margin="normal"
                  value={editedVideoData.videolink}
                  onChange={(e) =>
                    setEditedVideoData({
                      ...editedVideoData,
                      videolink: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Thumbnail Link"
                  fullWidth
                  margin="normal"
                  value={editedVideoData.thumnailLink}
                  onChange={(e) =>
                    setEditedVideoData({
                      ...editedVideoData,
                      thumnailLink: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Duration"
                  fullWidth
                  margin="normal"
                  value={editedVideoData.duration}
                  onChange={(e) =>
                    setEditedVideoData({
                      ...editedVideoData,
                      duration: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Description"
                  fullWidth
                  multiline
                  rows={4}
                  margin="normal"
                  value={editedVideoData.description}
                  onChange={(e) =>
                    setEditedVideoData({
                      ...editedVideoData,
                      description: e.target.value,
                    })
                  }
                />
                <FormControl fullWidth margin="normal">
                  <InputLabel>Instructor</InputLabel>
                  <Select
                    label="Instructor"
                    value={
                      isAddingNewInstructor
                        ? newInstructor
                        : editedVideoData.instructor
                    }
                    onChange={(e) => {
                      if (e.target.value === "addNew") {
                        // Trigger new instructor input
                        setIsAddingNewInstructor(true);
                        setNewInstructor(""); // clear the input field for new instructor
                        setEditedVideoData({
                          ...editedVideoData,
                          instructor: "",
                        });
                      } else {
                        // Set selected instructor
                        setIsAddingNewInstructor(false);
                        setEditedVideoData({
                          ...editedVideoData,
                          instructor: e.target.value,
                        });
                      }
                    }}
                  >
                    {/* Render existing instructors */}
                    {presentInstrutors.map((instructor, index) => (
                      <MenuItem key={index} value={instructor}>
                        {instructor}
                      </MenuItem>
                    ))}

                    {/* Option to add new instructor */}
                    <MenuItem value="addNew">Add New Instructor</MenuItem>
                  </Select>

                  {/* Show input for new instructor when 'Add New Instructor' is selected */}
                  {isAddingNewInstructor && (
                    <TextField
                      label="New Instructor"
                      fullWidth
                      margin="normal"
                      value={newInstructor}
                      onChange={(e) => setNewInstructor(e.target.value)}
                      onBlur={() => {
                        // Once input is done, save the new instructor in editedVideoData
                        if (newInstructor.trim()) {
                          setEditedVideoData({
                            ...editedVideoData,
                            instructor: newInstructor,
                          });
                        }
                      }}
                    />
                  )}
                </FormControl>

                <TextField
                  select
                  label="Select Category"
                  fullWidth
                  margin="normal"
                  value={editedVideoData.CategoryId}
                  onChange={(e) =>
                    setEditedVideoData({
                      ...editedVideoData,
                      CategoryId: e.target.value,
                    })
                  }
                >
                  {videoTypes.map((type, index) => (
                    <MenuItem key={index} value={type._id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>

                <FormControl className="mt-1" fullWidth>
                  <InputLabel id="video-level">Choose the Level</InputLabel>
                  <Select
                    labelId="video-level"
                    id="videoLevel"
                    name="videoLevel"
                    multiple
                    value={editedVideoData?.videoLevel}
                    onChange={handleVideoLevelChange}
                    label="Choose the Level"
                    renderValue={(selected) => {
                      if (Array.isArray(selected)) {
                        return selected.join(", ");
                      }

                      // Handle non-array cases
                      return selected || ""; // Display single value or empty string
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select The Level of the Video</em>
                    </MenuItem>
                    <MenuItem value="Beginner">
                      <Checkbox
                        checked={editedVideoData.videoLevel.includes(
                          "Beginner"
                        )}
                      />
                      <ListItemText primary="Beginner" />
                    </MenuItem>
                    <MenuItem value="Intermediate">
                      <Checkbox
                        checked={editedVideoData.videoLevel.includes(
                          "Intermediate"
                        )}
                      />
                      <ListItemText primary="Intermediate" />
                    </MenuItem>
                    <MenuItem value="Advanced">
                      <Checkbox
                        checked={editedVideoData.videoLevel.includes(
                          "Advanced"
                        )}
                      />
                      <ListItemText primary="Advanced" />
                    </MenuItem>
                  </Select>
                </FormControl>
                <DatePicker
                  selected={getCurrentDate()}
                  onChange={(date) =>
                    handleDateChange(date, setEditedVideoData)
                  }
                  dateFormat="MMMM d, yyyy"
                  customInput={<CalendarInput />}
                />

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Autocomplete
                    ref={tagsRef}
                    freeSolo
                    options={backendTags.map((tag) => tag.name)}
                    inputValue={query}
                    onInputChange={(event, newInputValue) =>
                      setQuery(newInputValue)
                    }
                    onChange={(e, option) => {
                      handleTagAdd(option);
                      setQuery("");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add Tag"
                        fullWidth
                        value={query}
                      />
                    )}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginRight: "10px",
                    }}
                  >
                    {editedVideoData.tags
                      ?.filter((tag) => tag) // Filter out null or undefined tags
                      .map((tag, index) => (
                        // debugger
                        <div key={index} className="tag">
                          {
                            tag?.name /* Use optional chaining to handle undefined tags */
                          }
                          <span onClick={() => handleTagRemove(tag)}>x</span>
                        </div>
                      ))}
                  </div>
                </div>

                <Form.Label>Products</Form.Label>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Autocomplete
                    freeSolo
                    options={backendProducts.map((product) => product.title)}
                    inputValue={productQuery}
                    onInputChange={(event, newInputValue) => {
                      setProductQuery(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add Product"
                        value={query}
                        fullWidth
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === "tab") {
                            handleProductAdd(e.target.value);
                          }
                        }}
                      />
                    )}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginRight: "10px",
                    }}
                  >
                    {editedVideoData.products
                      ?.filter((product) => product) // Filter out null or undefined tags
                      .map((product, index) => (
                        // debugger
                        <div key={index} className="tag">
                          {
                            product?.title /* Use optional chaining to handle undefined tags */
                          }
                          <span
                            onClick={() => handleProductRemove(product?._id)}
                          >
                            x
                          </span>
                        </div>
                      ))}
                  </div>
                </div>

                <FormCheck
                  className="mt-1"
                  type="checkbox"
                  id="isFeatured"
                  label="Add to Featured Playlist"
                  checked={editedVideoData.isFeatured}
                  onChange={() =>
                    setEditedVideoData((prevData) => ({
                      ...prevData,
                      isFeatured: !prevData.isFeatured,
                    }))
                  }
                />

                <div
                  style={{
                    marginTop: 16,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleSaveUser();
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </Fade>
      </Modal>

      {/* Material-UI Dialog for Delete Confirmation */}
      <Dialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this video?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirmationOpen(false)}
            color="primary"
          >
            No
          </Button>
          <Button
            onClick={() => handleDeleteVideo(editingUser.v_id)}
            color="primary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AllVideos;
