import React, { useState, useEffect, useRef, useContext } from "react";
import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Filter from "../../Asset/images/icon/Filters-lines.svg";
import axios from "axios";
import Video_title from "../Video_title";
import Card from "../Card";
import Cookies from "js-cookie";
import { VideoContext } from "../Video_player/VideoContext";
import { useNavigate } from "react-router-dom";
import checkApiStatus from "../SubscripStatus";
import { IoMdHeartEmpty } from "react-icons/io";
import { FcLike } from "react-icons/fc";
import { UserContext } from "../LoginContext/UserContext";
import FilterModal from "../FilterModal";
import { useSelector } from "react-redux";
import MobileViewSearch from "../MobileViewSearch";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import AddToPlayListModal from "../playlist/personal-playlist/AddToPlayListModal";
import toast from "react-hot-toast";
import api from "../../data/api";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";
import { useAtomValue } from "jotai";
import videoApi from "../../api/videoApi";

function LibraryMain() {
  const playerRef = useRef(null);
  const [responseData, setResponseData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user } = useContext(UserContext);
  const [tags, setTags] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDurations, setSelectedDurations] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [selectedLevels, setselectedLevels] = useState([]);
  const [localStorageData, setLocalStorageData] = useState(null);
  const [sortby, setSortBy] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const userInfo = useSelector((state) => state.authState.userInfo);
  const [modal, setModal] = useState(false);
  const [videos, setVideos] = useState("");
  const { selectedVideo, setSelectedVideo } = useContext(VideoContext);
  const [videoData, setVideoData] = useState({});
  const [videoData2, setVideoData2] = useState({});
  
  const [savedVideoIds, setSavedVideoIds] = useState([])
  const userData = useAtomValue(userInfoAtom)

  useEffect(() => {
    if (userData?.token) {
      videoApi.getAllSavedVideoIds(userData.user.userId)
        .then((res) => {
          setSavedVideoIds(res.data.savedVideos)
        })
      }
    }, [userData.token, responseData])
    
    useEffect(() => {
      // Retrieve the data from localStorage
      const data = window.localStorage.getItem("user");
  
      // Parse and set the data if it exists
      if (data) {
        setLocalStorageData(JSON.parse(data));
      }
    }, []);
    
    useEffect(() => {
      // Update the state with the selectedVideo when it changes
    if (user) {
      setLocalStorageData(user);
    }
  }, [user]);

  useEffect(() => {
    // Update localStorage with the latest value from state
    if (localStorageData) {
      window.localStorage.setItem("user", JSON.stringify(localStorageData));
    }
  }, [localStorageData]);

  useEffect(() => {
    const token = userData.token
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData.token]);

  // ----------| Add TO Playlist |--------------

  const handelSubmitPlaylist = async (_id) => {
    //setModal(true)
    try {
      const response = await api.videoPlayerPage(_id);
      if (response.status === 200) {
        setModal(false);
        const jsonData = response.data[0];
        setVideoData2(jsonData);
        setModal(true);
      }
    } catch (error) {
      setModal(false);
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    const data = window.localStorage.getItem("selectedVideo");

    if (data) {
      setVideoData(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    if (selectedVideo) {
      setVideoData(selectedVideo);
    }
  }, [selectedVideo]);

  const redirect = useNavigate();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/get/tags`).then((response) => {
      // console.log(response.data[0].name);
      setTags(response?.data);
      // console?.log('tags api calling');
    });
  }, [isOpen]);

 
  const handleSubmit = async (id, video) => {
    const isLiked = video?.videoDetails?.savedvideo || video?.savedvideo || (savedVideoIds?.length > 0 && savedVideoIds?.includes(video?.videoDetails?._id || video?._id));

    try {
      if (isLiked) {
        const response = await api.unsaveWishlist(id);
        // console.log(response.data);
        const uniqueIdentifier = id;
        if (response?.status === 200) {
          const updatedResponseData = responseData?.map((item) => {
            const updatedVideos = item?.videos?.map((video) => {
              if (video?.videoDetails?._id === uniqueIdentifier) {
                return {
                  ...video,
                  videoDetails: {
                    ...video?.videoDetails,
                    savedvideo: false, // Set savedvideo to false for the matched video
                  },
                };
              } else if (video?._id === uniqueIdentifier) {
                return {
                  ...video,
                  savedvideo: false, // Set savedvideo to false for the matched video
                };
              }
              return video;
            });
            return {
              ...item,
              videos: updatedVideos,
            };
          });

          // Update the state (setResponseData) with the updated responseData
          setResponseData(updatedResponseData);
        }
      } else {
        const response = await api.saveWishlist(id);
        if (response.status === 200) {
          // console.log(response.data);
          const uniqueIdentifier = id;
          const updatedResponseData = responseData?.map((item) => {
            const updatedVideos = item?.videos?.map((video) => {
              if (video?.videoDetails?._id === uniqueIdentifier) {
                return {
                  ...video,
                  videoDetails: {
                    ...video?.videoDetails,
                    savedvideo: true, // Set savedvideo to false for the matched video
                  },
                };
              } else if (video?._id === uniqueIdentifier) {
                return {
                  ...video,
                  savedvideo: true, // Set savedvideo to false for the matched video
                };
              }
              return video;
            });
            return {
              ...item,
              videos: updatedVideos,
            };
          });

          setResponseData(updatedResponseData);
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };
  //--------for video player page------
  const handlePlayerVideo = async (id) => {
    const apistatus = await checkApiStatus(userData.token);
    if (isLoggedIn) {
      if (apistatus) {
        // User is logged in and subscribed, redirect to PlayerPage
        redirect(`/PlayerPage?v=${id}`);
      } else {
        // User is logged in but not subscribed, redirect to PlanPage
        redirect("/Plans");
      }
    } else {
      // User is not logged in, redirect to SignUp
      redirect("/SignUp");
    }
  };

  const handleTagChange = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((prevTag) => prevTag !== tag)
        : [...prevTags, tag]
    );
  };

  const handleDurationChange = (durationValue) => {
    setSelectedDurations((prevDurations) =>
      prevDurations.includes(durationValue)
        ? prevDurations.filter((prevDuration) => prevDuration !== durationValue)
        : [...prevDurations, durationValue]
    );
  };

  useEffect(() => {
    const userId = userInfo && userInfo?.userId ? userInfo?.userId : "";
    async function fetchData() {
      console.log(selectedInstructors)
      try {
        await fetchVideosByTags(
          selectedTags,
          selectedDurations,
          selectedLevels,
          selectedInstructors
        );
        //console.log("hello")
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (responseData?.length > 0 && instructors.length === 0) {
      console.log("Fetched video data from instructor useEffect:", responseData);
      setInstructors([
        ...new Set(
          responseData
            .flatMap(category => category.videos)
            .filter(video => video.instructor !== undefined && video.instructor !== null)
            .map(video => video.instructor)
        )
      ]);
    }
  }, [responseData])

  useEffect(() => {
    console.log('instructors: ', instructors)
  }, [instructors])

  const fetchVideosByTags = async (
    selectedTags,
    selectedDurations,
    selectedLevels,
    sort_by = "latest",
    selectedInstructors
  ) => {
    const userId = userInfo && userInfo?.userId ? userInfo?.userId : "";
    try {
      // Make the API call with the tag IDs directly
      console.log("called from filter modal", selectedTags);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/library/page/video?userId=${userId}`,
        {
          params: {
            vtags: selectedTags?.map((tag) => tag).join(","),
            durations: selectedDurations?.map((duration) => duration).join(","),
            levels: selectedLevels?.map((level) => level).join(","),
            instructors: selectedInstructors?.map((instructor) => instructor).join(","),
            sort_by: sort_by,
          },
        }
      );

      setResponseData(response?.data?.libraryVideosData);
    } catch (error) {
      console.error("Error fetching filtered videos: ", error);
    }
  };

  const handleFilterChange = (event) => {
    setSortBy(event.target.value);
    fetchVideosByTags(
      selectedTags,
      selectedDurations,
      selectedLevels,
      selectedInstructors,
      event.target.value
    );
  };

  const handleShowMoreClick = (categoryName) => {
    redirect(
      `/${categoryName
        .replace(/\s+/g, "")
        .replace(
          /^(.)(.*)$/,
          (_, first, rest) => first.toUpperCase() + rest.toLowerCase()
        )}`
    );
    console.log(`/${categoryName}`);
  };

  useEffect(() => {
    // Add event listener to detect clicks outside c-filter_btn-one
    const handleClickOutside = (event) => {
      const filterBtn = document.querySelector(".unique");
      if (filterBtn && !filterBtn.contains(event.target)) {
        setIsOpen(false);
      }
    };
    // Attach the event listener
    document.addEventListener("click", handleClickOutside);
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOpenFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const handleApplyFilter = (
    selectedTags,
    selectedDurations,
    selectedLevels,
    selectedInstructors
  ) => {
    setSelectedTags(selectedTags);
    setselectedLevels(selectedLevels);
    setSelectedDurations(selectedDurations);
    console.log("from apply filter",selectedInstructors)
    fetchVideosByTags( selectedTags,
      selectedDurations,
      selectedLevels,
      selectedInstructors)
    setSelectedInstructors(selectedInstructors);
    setIsFilterModalOpen(false);
  };

  const handleResetFilter = () => {
    // Reset your filter options
    setSelectedTags([]);
    setSelectedDurations([]);
    setselectedLevels([]);
    setSelectedInstructors([]);
    // You may need additional logic to reset other filter options
  };

  return (
    <div>
      <div id="c-mobile_search">
        <div class="row m-0 c-tab_box">
          <div class="p-0">
            <div className="c-filter_btn">
              <MobileViewSearch />
              <div className="c-filter_btn-one c-mobile_filter_btn1">
                <img src={Filter} onClick={handleOpenFilterModal} alt="" />
                <span className="filterby-2">
                  {isFilterModalOpen && (
                    <FilterModal
                      isOpen={isFilterModalOpen}
                      tags={tags}
                      checkedTags={selectedTags}
                      checkedDuration={selectedDurations}
                      checkedLevels={selectedLevels}
                      handleDurationChange={handleDurationChange}
                      handleTagChange={handleTagChange}
                      handleApplyFilter={handleApplyFilter}
                      handleResetFilter={handleResetFilter}
                      handleClose={handleCloseFilterModal}
                      checkedInstructors={selectedInstructors}
                      instructors={instructors}
                    />
                  )}
                </span>
              </div>
              <div className="c-filter_btn-one c-mobile_filter_btn2">
                <span>Sort by</span>
                <select className="filterby" onChange={handleFilterChange}>
                  <option value={"latest"}>Newest</option>
                  <option value={"oldest"}>Oldest</option>
                  <option value={"title_asc"}>Title (A - Z)</option>
                  <option value={"title_desc"}>Title (Z - A)</option>
                  <option value={"long_duration"}>Longest</option>
                  <option value={"short_duration"}>Shortest</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="tab-content">
          <div id="home" className="tab-pane fade in active show">
            {responseData?.map((category, index) => (
              <div key={index} id={category?.name?.split(" ")[0]}>
                <div key={index} className="c-tab_cata-text">
                  {category.name.includes("LESSONS") ? (
                    <div id="c-mobile_tab_hide" className=" c-tab_cata mb-n9">
                      <div className="row m-0 c-tab_box">
                        <div className="col p-0">
                          <div className="c-filter_btn c-filter_btn-lessons">
                            <div
                              className="c-filter_btn-one c-filter_btn-tags unique "
                              style={{ cursor: "pointer", padding: "0px" }}
                            >
                              <span
                                onClick={handleOpenFilterModal}
                                className="c-filters-btn"
                                style={{
                                  padding: "10px 16px",
                                  display: "inline-block",
                                }}
                              >
                                {" "}
                                <img src={Filter} alt="" />
                                Filters
                              </span>
                              {/* Render FilterModal */}
                              {isFilterModalOpen && (
                                <FilterModal
                                  isOpen={isFilterModalOpen}
                                  tags={tags}
                                  checkedTags={selectedTags}
                                  handleTagChange={handleTagChange}
                                  checkedDuration={selectedDurations}
                                  checkedLevels={selectedLevels}
                                  handleApplyFilter={handleApplyFilter}
                                  handleResetFilter={handleResetFilter}
                                  handleClose={handleCloseFilterModal}
                                  checkedInstructors={selectedInstructors}
                                  instructors={instructors}
                                />
                              )}
                            </div>
                            <div className="c-filter_btn-one">
                              <span>Sort by</span>
                              <select
                                className="filterby"
                                onChange={handleFilterChange}
                              >
                                <option value={"latest"}>Newest</option>
                                <option value={"oldest"}>Oldest</option>
                                <option value={"title_asc"}>
                                  Title (A - Z)
                                </option>
                                <option value={"title_desc"}>
                                  Title (Z - A)
                                </option>
                                <option value={"long_duration"}>Longest</option>
                                <option value={"short_duration"}>
                                  Shortest
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  <h4>{category?.name}</h4>
                </div>
                <div className="c-video">
                  <div className="row m-0 c-video_textrow">
                    <div className="col-md-8 c-video_textcol">
                      <h4>{category?.name}</h4>
                    </div>
                    <div className="col-md-4 c-video_textcol">
                      <p
                        className="c-view_more"
                        onClick={() => handleShowMoreClick(category?.name)}
                      >
                        View all videos
                      </p>
                    </div>
                  </div>
                </div>
                {/* // Video card starts from here */}
                <div className="c-video_list">
                  <div class="row m-0">
                      {category?.videos?.map((video, index) => (
                        /////////////////////////////////////////////////////Mobile class/////////////////
                        <div key={index} className="col-md-3 c-mobile_col">
                          <div className="c-video_box">
                            <div className="c-video_icon-box">
                              <div>
                                <Card
                                  thumb_url={
                                    video?.videoDetails?.thumnailLink ||
                                    video?.thumnailLink
                                  }
                                  video_url={
                                    video?.videoDetails?.videolink ||
                                    video?.videoDetails
                                  }
                                  thumbnail_pic={
                                    video?.videoDetails?.thumnailLink ||
                                    video?.thumnailLink
                                  }
                                  video_duration={
                                    video?.videoDetails?.duration ||
                                    video?.duration
                                  }
                                  playerRef={playerRef}
                                  onClick={() =>
                                    handlePlayerVideo(
                                      video?.videoDetails?._id || video?._id
                                    )
                                  }
                                />
                              </div>
                              {isLoggedIn && (
                                <PlaylistAddRoundedIcon
                                  fontSize="large"
                                  style={{
                                    cursor: "pointer",
                                    boxSizing: "border-box",
                                    position: "absolute",
                                    top: "1px",
                                    right: "1px",
                                  }}
                                  onClick={() =>
                                    handelSubmitPlaylist(
                                      video.videoDetails?._id || video?._id
                                    )
                                  }
                                />
                              )}
                              {isLoggedIn && (
                                <div className="c-video_time">
                                  <span
                                    id={video?.videoDetails?._id || video?._id}
                                    onClick={(e) => {
                                      const id = e.currentTarget.id; // Accessing the id from the clicked element
                                      handleSubmit(id, video);
                                    }}
                                  >
                                    {video?.videoDetails?.savedvideo ||
                                      video?.savedvideo || (savedVideoIds?.length > 0 && savedVideoIds?.includes(video?.videoDetails?._id || video?._id)) ? (
                                      <FcLike />
                                    ) : (
                                      <IoMdHeartEmpty />
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                            {/* Add conditional rendering for Video_title */}
                            <div>
                              {video?.videoDetails ? (
                                <Video_title
                                  title={video.videoDetails.title || ""}
                                  tags={(video.videoDetails.tagsDetails || [])
                                    .map((tag) => ({
                                      _id: tag._id,
                                      name: tag.name,
                                      color: tag.color,
                                      priority: tag.priority || 0,
                                    }))
                                    .sort((a, b) => a.priority - b.priority)}
                                  releaseDate={
                                    video.videoDetails.releaseDateTime || ""
                                  }
                                  onClick={() =>
                                    handlePlayerVideo(
                                      video?.videoDetails?._id || video?._id
                                    )
                                  }
                                />
                              ) : (
                                <Video_title
                                  title={video?.title || ""}
                                  tags={
                                    (video?.tagsDetails || [])
                                      .map((tag) => ({
                                        _id: tag._id,
                                        name: tag.name,
                                        color: tag.color,
                                        priority: tag.priority || 0, // Assign priority or default to 0
                                      }))
                                      .sort((a, b) => a.priority - b.priority) // Sort tags based on priority
                                  }
                                  releaseDate={video?.releaseDateTime || ""}
                                  onClick={() =>
                                    handlePlayerVideo(
                                      video?.videoDetails?._id || video?._id
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {/* //////////////////////Mobile Show More Button //////////// */}
                <div className="c-mobile_show-btn">
                  <button onClick={() => handleShowMoreClick(category?.name)}>
                    Show More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <AddToPlayListModal
        modal={modal}
        setModal={setModal}
        activeObject={videoData2}
        viewData={videos}
      />
    </div>
  );
}

export default LibraryMain;
