import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import XButton from "../XButton/XButton";

const ChangeUserModal = ({ open, handleClose,handleCancelClick, userType, onSave}) => {
 
 const handleConfirmClick = () => {
      onSave();
};

  return (
    
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            p: 6,
            backgroundColor: "#27272a",
            margin: "auto",
            mt: "20vh",
            width: 600,
            borderRadius: "10px",
            position: "relative",
            color: "white",
          }}
        >
        <div className = "change-user-text"> 
          {userType === "individual-user" ?(
            <p> You are currently registered as a user on our platform. If you switch your account type, you will lose your previous account subscription and data. Are you sure you want to convert to a Studio Account?</p>
          ):(
            <p> You are currently registered as {userType} , if you switch your account type you will lose your previous account subscriptions and data</p>
          )}
         
        </div>
        <div className= "change-user-button-section">
        <button
            className="c-form_my-btn"
            onClick={handleConfirmClick}
           
          >
            Confirm
          </button>
        <button
            className="no-seats-left-button"
            onClick={handleCancelClick}
           
          >
            Cancel
          </button>
       

        </div>

       

         

          
        </Box>
      </Modal>
   
  );
};

export default ChangeUserModal;
